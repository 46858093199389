<!--
 * @Description: 项目管理
 * @Author: ChenXueLin
 * @Date: 2021-09-09 16:10:01
 * @LastEditTime: 2022-07-15 16:09:44
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="projectId">
              <el-input
                v-model="searchForm.projectId"
                placeholder="项目ID"
                title="项目ID"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="projectName">
              <el-input
                v-model="searchForm.projectName"
                placeholder="项目名称"
                title="项目名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="projectLeaderName">
              <el-input
                v-model="searchForm.projectLeaderName"
                placeholder="负责人名称"
                title="负责人名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="phoneNo">
              <el-input
                v-model="searchForm.phoneNo"
                placeholder="联系电话"
                title="联系电话"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="projectStatus">
              <e6-vr-select
                v-model="searchForm.projectStatus"
                :data="projectStatusList"
                placeholder="项目状态"
                title="项目状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="createByName">
              <el-input
                v-model="searchForm.createByName"
                placeholder="创建人"
                title="创建人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="创建时间（始）"
                end-placeholder="创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="创建项目" @click="addProject"> </i>
          <!-- <i
            class="e6-icon-prohibit_line"
            title="批量关闭项目"
            @click="closeProject"
          ></i> -->
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @selection-change="handleSelectionChange"
          highlight-current-row
          ref="elTable"
          :row-key="
            row => {
              return row.projectId;
            }
          "
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="70">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getProjectList, findDownList, closeProject } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "projectManage",
  components: {},
  data() {
    return {
      total: 0,
      loading: false,
      projectStatusList: [], //项目状态
      searchForm: {
        projectId: "", //项目ID
        projectName: "", //项目名称
        projectLeaderName: "", //项目负责人名称
        phoneNo: "", //联系电话
        projectStatus: "", //状态
        createByName: "", //创建人
        createTime: [],
        createdTimeEnd: "",
        createdTimeStart: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "projectId",
          display: true,
          fieldLabel: "项目ID",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "projectName",
          display: true,
          fieldLabel: "项目名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "projectLeaderName",
          display: true,
          fieldLabel: "负责人名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phoneNo",
          display: true,
          fieldLabel: "联系电话",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "projectStatusName",
          display: true,
          fieldLabel: "项目状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskCount",
          display: true,
          fieldLabel: "关联任务单",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "createByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [],
      refTable: "elTable",
      queryListAPI: getProjectList
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createdTimeStart = val[0] || "";
        this.searchForm.createdTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [findDownList(["projectStatus"])];
        let [projectStatusRes] = await Promise.all(promiseList);
        //项目状态
        this.projectStatusList = this.getFreezeResponse(projectStatusRes, {
          path: "data.projectStatus"
        });
      } catch (error) {
        printError(error);
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.projectId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    /********批量关闭操作************/
    //点击批量关闭项目
    //关闭项目
    closeProject() {
      if (!this.selColumnId.length) {
        this.$message.warning("请先勾选要关闭的项目");
        return;
      }
      this.$confirm("是否确认关闭项目？", "关闭项目", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.closeTaskReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //关闭项目请求
    async closeTaskReq() {
      try {
        this.loading = true;
        let res = await closeProject({ projectIdList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("关闭成功");
          this.queryList();
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "operationsCenter/projectDetail",
          params: {
            refresh: true,
            projectId: row.projectId
          }
        });
      }
    },
    addProject() {
      this.routerPush({
        name: "operationsCenter/addProject",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
